import React from 'react'
import { Navigate } from 'react-router-dom'
import { isAuthenticated } from './auth'
import PropTypes from 'prop-types'

const ProtectedRoute = ({ element: Component, ...rest }) => {
  return isAuthenticated() ? <Component {...rest} /> : <Navigate to="/login" />
}

ProtectedRoute.propTypes = {
  element: PropTypes.elementType.isRequired,
}
export default ProtectedRoute
